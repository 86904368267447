interface Categoria {
  nome: string;
  nota: string | null; // string por conta dos numeros com virgula
  comentario: string | null;
  nivelSelecionado: Nivel;
  nivelBasico: string[];
  nivelIntermediario: string[];
  nivelAvancado: string[];
}

export interface Item {
  nome: string;
  categorias: Categoria[];
}

export enum Nivel {
  BASICO = "basico",
  INTERMEDIARIO = "intermediario",
  AVANCADO = "avancado",
}

export function getPerguntasByNivel(categoria: Categoria) {
  switch (categoria.nivelSelecionado) {
    case Nivel.BASICO:
      return categoria.nivelBasico;
    case Nivel.INTERMEDIARIO:
      return categoria.nivelIntermediario;
    case Nivel.AVANCADO:
      return categoria.nivelAvancado;
  }
}

export function getVotingOptions(nivel: string) {
  switch (nivel) {
    case Nivel.BASICO:
      return [
        "1,0",
        "1,25",
        "1,50",
        "1,75",
        "2,0",
        "2,25",
        "2,50",
        "2,75",
        "3,0",
        "3,25",
        "3,50",
        "3,75",
        "4,0",
        "4,25",
        "4,50",
        "4,75",
        "5,0",
      ];
    case Nivel.INTERMEDIARIO:
      return [
        "5,25",
        "5,50",
        "5,75",
        "6,0",
        "6,25",
        "6,50",
        "6,75",
        "7,0",
        "7,25",
        "7,50",
        "7,75",
        "8,0",
      ];
    case Nivel.AVANCADO:
      return ["8,25", "8,50", "8,75", "9,0", "9,25", "9,50", "9,75", "10,0"];
    default:
      return [];
  }
}

export const bAgile: Item[] = [
  {
    nome: "Segurança como um pré-requisito",
    categorias: [
      {
        nome: "Clean Code e código sustentável",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todo o time de desenvolvimento segue as diretrizes do projeto para configuração e uso de ferramentas de inspeção de código?",
          "Todo o time de desenvolvimento se preocupa em obedecer a estrutura interna do código da aplicação?",
          "Todo o time de desenvolvimento se preocupa em escrever código organizado, performático e legível?",
          "A equipe realiza Code Review internamente?",
        ],
        nivelIntermediario: [
          "(B) Todo o time de desenvolvimento segue as diretrizes do projeto para configuração e uso de ferramentas de inspeção de código?",
          "Ao encontrar código que foge das práticas internas adotadas é realizada alguma ação (como: encaminhar para a equipe responsável, refatorar o código, etc)?",
          "A equipe é crítica e propõe melhorias em relação à estrutura interna do código da aplicação?",
          "No code review externo e interno, todo o time de desenvolvimento analisa se o código está organizado, performático e legível?",
          "Ao criar novos padrões de códigos e funções utilitárias, a equipe se preocupa em compartilhar isso com as outras equipes?",
          "A equipe possui o indicador do Tipo de esforço na categoria Dívida Técnica dentro do esperado pelo projeto?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Testes automatizados",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe reserva tempo na sprint para construção de testes automatizados?",
          "A construção dos testes automatizados visa a qualidade dos testes e não apenas a cobertura do código?",
          "No Code Review interno há preocupação em validar se os padrões de código foram seguidos nos testes escritos?",
          "Há evolução dos testes automatizados quando erros são descobertos para garantir que esses erros não ocorram novamente (garantir teste de regressão dessa funcionalidade)? ",
        ],
        nivelIntermediario: [
          "(B) A construção dos testes automatizados visa a qualidade dos testes e não apenas a cobertura do código?",
          "(B) Há evolução dos testes automatizados quando erros são descobertos para garantir que esses erros não ocorram novamente (garantir teste de regressão dessa funcionalidade)?",

          "A equipe reserva tempo no planejamento para construção de testes automatizados e consegue entregá-los juntos com a demanda?",
          "No Code Review interno há preocupação em validar os cenários e se os padrões de código foram seguidos nos testes escritos?",
          "No Code Review externo há preocupação em validar se os padrões de código foram seguidos nos testes escritos?",
          "Todo o time de desenvolvimento busca utilizar testes automatizados para facilitar o desenvolvimento? (por exemplo: durante o desenvolvimento são construídos testes que validem se a funcionalidade está correta e são aplicados conceitos de TDD)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Qualidade E2E",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe se preocupa se o tempo de resposta para realizar uma atividade/ação é adequado?",
          "As funcionalidades são testadas simulando o mais próximo do cenário real (em volume de dados)?",
          "As últimas entregas para a equipe de qualidade foram realizadas com poucos bugs críticos?",
          "As últimas entregas para produção foram realizadas sem bugs críticos?",
        ],
        nivelIntermediario: [
          "(B) A equipe se preocupa se o tempo de resposta para realizar uma atividade/ação no sistema é adequado?",
          "(B) As últimas entregas para produção foram realizadas sem bugs críticos?",

          "As funcionalidades são testadas simulando o mais próximo do cenário real (em volume de dados e de requisições)?",
          "As últimas entregas para a equipe de qualidade foram realizadas com quase nenhum bug crítico?",
          "A equipe possui o indicador do Tipo de esforço na categoria Correção dentro do esperado pelo projeto?",
          "A equipe monitora e busca otimizar o lead time de bugs originados em ambiente de produção?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Segurança de dados",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe busca falhas de segurança durante a execução? (por exemplo: testes de vulnerabilidade, testes de permissões dos usuários, validações)",
          "A equipe avalia se é necessário garantir a rastreabilidade de manipulação dos dados dentro do sistema? (por exemplo: trilhas de auditoria, etc)",
          "A equipe busca armazenar e exibir apenas as informações necessárias para a demanda que está desenvolvendo?",
        ],
        nivelIntermediario: [
          "(B) A equipe busca armazenar e exibir apenas as informações necessárias para a demanda que está desenvolvendo?",

          "A equipe busca falhas de segurança durante a execução? (por exemplo: testes de vulnerabilidade, testes de permissões dos usuários, validações, falhas e controles complexos na API, etc)",
          "A equipe avalia se é necessário garantir a rastreabilidade de manipulação dos dados dentro do sistema ou via API? (por exemplo: trilhas de auditoria)",
          "A equipe conhece e leva em consideração as diretrizes da LGPD?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Práticas DevOps",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time de desenvolvimento e PO possuem conhecimento suficiente para realizar operações básicas no Git (por exemplo: clone, pull, push)?",
          "Todo o time de desenvolvimento e PO conseguem configurar e rodar o ambiente local do projeto de forma independente?",
          "Todo o time de desenvolvimento sabe identificar erros no pipeline do pull request/merge do projeto?",
          "A equipe sabe identificar e acompanhar erros e métricas de desempenho nas ferramentas de monitoramento do projeto? (por exemplo: Sentry e Grafana)",
          "Todo o time de desenvolvimento entende a estrutura básica do projeto, como pastas e arquivos principais?",
        ],
        nivelIntermediario: [
          "Todo o time de desenvolvimento e PO utilizam práticas intermediárias no Git? (por exemplo: criação e gerenciamento de branches, resolução de conflitos, etc)",
          "Todo o time de desenvolvimento e PO possuem conhecimento suficiente para subir a aplicação localmente, identificar e resolver erros mais simples nesse processo? (por exemplo: erro no banco por estar em estado inválido, etc)",
          "Todo o time de desenvolvimento consegue diagnosticar e corrigir falhas que ocorrem no pipeline de pull request/merge de maneira autônoma?",
          "Todo o time de desenvolvimento consegue otimizar seu fluxo de trabalho com docker? (gerenciamento de imagens, commit de imagens com cenários específicos, etc)",
          "A equipe é capaz de configurar monitoramento proativo para identificar problemas em tempo real nas aplicações utilizando as ferramentas de monitoramento do projeto? (por exemplo: Sentry e Grafana)",
          "Todo o time de desenvolvimento entende claramente como as ferramentas de gerenciamento de dependências funcionam e estão organizadas? (por exemplo: ciclo de vida, diferença entre os comandos, etc)",
        ],
        nivelAvancado: [
          "(I) Todo o time de desenvolvimento e PO possuem conhecimento suficiente para subir a aplicação localmente, identificar e resolver erros mais simples nesse processo? (por exemplo: erro no banco por estar em estado inválido, etc)",
          "Todo o time de desenvolvimento utiliza práticas avançadas no Git para otimizar o fluxo de trabalho e resolver conflitos complexos? (por exemplo: rebase, cherry-pick)",
          "Todo o time de desenvolvimento consegue identificar e otimizar gargalos no pipeline de request/merge dos módulos sob sua responsabilidade direta, buscando melhorar o tempo de build e deploy?",
          "Todo o time de desenvolvimento busca otimizar seu fluxo de trabalho com ferramentas e métodos diversos? (por exemplo: docker, automatizar a configuração do ambiente local de forma eficiente, utilização de scripts, etc)",
          "A equipe é capaz de configurar alertas automáticos e relatórios detalhados sobre falhas e desempenho da aplicação, utilizando práticas de observabilidade? (por exemplo Análise de Logs em Tempo Real, Rastreamento de requisições, métricas)",
          "A equipe consegue personalizar e otimizar ferramentas de gerenciamento de dependências para atender as necessidades específicas do projeto? (por exemplo: evitar conflitos de versão, problemas de performance, etc)?",
          "A equipe possui políticas de monitoramento e performance do sistema?",
        ],
      },
    ],
  },
  {
    nome: "Experimente e aprenda rápido",
    categorias: [
      {
        nome: "Práticas Lean-Agile",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe possui gestão visual no board e ela é simples e clara?",
          "A equipe possui e segue o documento de políticas de gestão do board?",
          "Todos da equipe conhecem os objetivos de cada cerimônia realizada?",
          "Todos da equipe consideram que as cerimônias realizadas são úteis e coerentes com as suas necessidades?",
          "Todos da equipe conhecem o método ágil de trabalho no Laboratório e compreendem como os conceitos e processos que rodam na equipe?",
          "A equipe consegue diferenciar o que é essencial e o que não agrega valor no processo de desenvolvimento?",
        ],
        nivelIntermediario: [
          "A equipe consegue olhar de forma crítica para o board e identificar gargalos?",
          "Todos da equipe conhecem e seguem as políticas de gestão do board da equipe?",
          "Todos da equipe sentem que as cerimônias são feitas da melhor maneira possível em relação a tempo, escopo, dinâmica e participação?",
          "Todos da equipe entendem o método ágil praticado no Laboratório e adaptam seus conceitos e processos à realidade e às necessidades da equipe?",
          "A equipe consegue identificar e eliminar o que não agrega valor (desperdícios) em seus processos?",
          "A equipe identifica e discute a respeito de possíveis riscos antes de iniciar novas demandas?",
        ],
        nivelAvancado: [
          "A equipe utiliza o board para identificar e agir proativamente sobre gargalos, tendências ou oportunidades de melhoria contínua?",
          "Todos da equipe conhecem e seguem as políticas de gestão do board da equipe, que são revisadas regularmente baseadas em feedbacks, análises do fluxo de trabalho e suas métricas?",
          "Todos da equipe contribuem ativamente para que as cerimônias ocorram da melhor maneira possível em relação a tempo, escopo, dinâmica e participação?",
          "A equipe busca testar novas práticas e propor inovações em seu método ágil de trabalho, de modo a compartilhar e influenciar com outras equipes?",
          "A equipe possui práticas estruturadas para prever o que não irá gerar valor (desperdícios) de modo a atuar rapidamente sobre eles antes que aconteçam? (por exemplo: tarefas redundantes, retrabalho, gargalos)?",
          "A equipe utiliza práticas e/ou processos para gerir seus riscos regularmente de modo a identificá-los e traçar planos de contingência antes que eles aconteçam?",
        ],
      },
      {
        nome: "Métricas ",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todos da equipe conhecem as métricas formalizadas pelo laboratório?",
          "A equipe faz o acompanhamento das métricas semanalmente?",
          "A equipe se baseia nas métricas para o planejamento das sprints?",
          "A equipe considera que as métricas coletadas são úteis e coerentes com as suas necessidades?",
        ],
        nivelIntermediario: [
          "Todos da equipe entendem o significado de cada métrica utilizada e os fatores que influenciaram seu cálculo ao fim das sprints?",
          "A equipe acompanha rotineiramente suas métricas de modo a ajustar o trabalho conforme necessário para o atingimento do que foi planejado?",
          "A equipe utiliza métricas para acompanhamento e orientação de decisões em relação ao planejamento ao longo da sprint? (por exemplo: burndown)",
          "Todos da equipe consideram que as métricas coletadas possuem qualidade suficiente para atender às suas necessidades e refletem, de maneira precisa, o desempenho durante a sprint?",
          "Na ausência do SM, a equipe consegue gerar as métricas?",
          "As métricas são coletadas e calculadas automaticamente?",
          "A equipe usa e acompanha o indicador do tipo de esforço?",
        ],
        nivelAvancado: [
          "(I) Todos da equipe consideram que as métricas coletadas possuem qualidade suficiente para atender às suas necessidades e refletem, de maneira precisa, o desempenho durante a sprint?",
          "Todos da equipe conseguem identificar oportunidades de melhoria no fluxo de desenvolvimento e nos processos com base nas métricas analisadas?",
          "A equipe utiliza algum tipo de análise de tendência para acompanhar a evolução das métricas ao longo da sprint?",
          "A equipe utiliza as métricas para prever possíveis gargalos nas entregas da equipe e age sobre isso?",
          "A equipe possui dashboard de métricas e suas informações são claras, relevantes e de qualidade suficiente para apoiar a tomada de decisão e acompanhamento do trabalho?",
          "Na ausência do SM, a equipe consegue analisar e tomar ações com base nas métricas?",
          "A equipe possui um dashboard automatizado para apresentação das suas métricas?",
          "A equipe usa o indicador de tipo de esforço para ajustar o planejamento e balancear a carga de trabalho de modo a atender o comportamento esperado pelo projeto?",
        ],
      },
      {
        nome: "Comprometimento com o produto (entregas)",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Existe um sentimento de responsabilidade compartilhada na equipe ao garantir a qualidade das entregas?",
          "Todos da equipe estão comprometidos com os prazos?",
          "A equipe acompanha e atua em correções e melhorias prioritárias do produto após sua finalização? (por exemplo: PO entra na planilha de satisfação e acompanha a entrega?)",
          "As entregas da equipe são bem recebidas pelos usuários finais? (por exemplo: poucos feedbacks negativos, dúvidas em relação ao uso da funcionalidade, bugs, etc)",
        ],
        nivelIntermediario: [
          "(B) Existe um sentimento de responsabilidade compartilhada na equipe ao garantir a qualidade das entregas?",
          "(B) Todos da equipe estão comprometidos com os prazos?",
          "A equipe acompanha e atua em correções e melhorias prioritárias do produto após sua finalização? (por exemplo: acompanhamento da satisfação do usuário, estatísticas do Grafana/métricas de desempenho, etc)",
          "As entregas da equipe são bem recebidas pelos usuários finais e pela equipe de qualidade? (por exemplo: poucos feedbacks negativos, dúvidas em relação ao uso da funcionalidade, bugs, etc)",
          "A equipe se sente motivada e engajada para levantar e apresentar argumentos para o cliente buscando o que é melhor para os usuários do sistema?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Compartilhamento de conhecimento",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe promove a troca de conhecimentos de forma contínua no dia a dia? (por exemplo: capacitações internas, pair-programming, etc)",
          "A equipe mostra as evoluções das funcionalidades para os bridgers de acordo com o esperado pelo projeto?",
        ],
        nivelIntermediario: [
          "(B) A equipe promove a troca de conhecimentos de forma contínua no dia a dia? (por exemplo: capacitações internas, pair-programming, etc)",
          "(B) A equipe mostra as evoluções das funcionalidades para os bridgers de acordo com o esperado pelo projeto?",
          "A equipe compartilha conhecimento com outros times? (por exemplo: Compartilhar dá +XP, através do Slack com artigos, fórum, evoluções técnicas, treinamentos entre equipes, troca de experiências entre POs/SMs/TLs, etc)?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Ritmo das Sprints",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe acompanha as issues para que o desenvolvimento ocorra como planejado? (por exemplo: monitora tempo da issue em cada etapa do processo, se necessário oferece ajuda para o responsável pela issue, etc)",
          "A equipe toma ações quando necessário se as issues atrasam? (por exemplo: busca auxílio de outras equipes, realiza replanejamento e/ou repriorização, etc)",
          "A equipe possui 50% das sprints concluídas no ciclo com o aproveitamento do esforço planejado dentro do esperado pelo projeto?",
          "A equipe discute e categoriza o motivo de atrasos das issues?",
        ],
        nivelIntermediario: [
          "A equipe acompanha riscos de atraso e toma ações com antecedência?",
          "A equipe elabora planos de ação para minimizar os motivos que geram atraso nas issues?",
          "A equipe possui 70% das sprints concluídas no ciclo com o aproveitamento do esforço planejado dentro do esperado pelo projeto?",
          "A equipe possui consistências nas suas entregas: coeficiente de variação da taxa de eficiência abaixo do esperado pelo projeto?",
          "A equipe toma decisões através do uso do gráfico de burndown?",
        ],
        nivelAvancado: [],
      },
    ],
  },
  {
    nome: "Valor a todo instante",
    categorias: [
      {
        nome: "Issues e Épicos",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Quando são incluídas no Sprint Backlog, as tarefas possuem as informações necessárias para o desenvolvimento descritas ou referenciadas? (por exemplo: critérios de aceitação/regras, contexto, protótipo, objetivo/descrição, etc)",
          "As issues épicas estão sempre atualizadas, organizadas e de fácil entendimento? (escopo, descrição, issues relacionadas, análise de impacto, etc)",
          "As issues são claras para todos os membros da equipe?",
          "A equipe mantém o milestone das issues atualizado e compatível com o tempo necessário para desenvolvimento e estabilização da demanda, incluindo validação da equipe de qualidade externa quando necessária?",
          "A equipe se preocupa em garantir que as issues épicas concluídas estão compatíveis com o que foi desenvolvido? (por exemplo: o escopo descrito, links para protótipos, milestone que foi entregue, etc)",
        ],
        nivelIntermediario: [
          "(B) Quando são incluídas no Sprint Backlog, as tarefas possuem as informações necessárias para o desenvolvimento descritas ou referenciadas? (por exemplo: critérios de aceitação/regras, contexto, protótipo, objetivo/descrição, etc)",
          "(B) A equipe se preocupa em garantir que as issues épicas concluídas estão compatíveis com o que foi desenvolvido? (por exemplo: o escopo descrito, links para protótipos, milestone que foi entregue, etc)",
          "(B) As issues épicas estão sempre atualizadas, organizadas e de fácil entendimento? (escopo, descrição, issues relacionadas, análise de impacto, etc)",
          "As issues criadas pela equipe seguem um padrão de descrição e estrutura?",
          "Todos da equipe se preocupam em descrever issues que fiquem claras para todos os membros do projeto?",
          "Todas as issues que são incluídas na sprint seguem o padrão de DoR (Definition of Ready) definido pela equipe?",
          "A equipe é proativa na definição da milestone das issues, além de mantê-la atualizada e compatível com o tempo necessário para desenvolvimento e estabilização da demanda, incluindo validação da equipe de qualidade externa quando necessária?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "User eXperience",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "O time de desenvolvimento discute e se envolve na etapa de descoberta do produto?",
          "A equipe sabe delimitar as ideias e implementá-las, alinhando as necessidades do usuário com as regras e restrições de negócio definidas pelo cliente?",
          "A equipe utiliza as etapas do processo de b_thinking em suas demandas?",
          "A equipe utiliza os métodos e ferramentas propostos no b_thinking em suas demandas?",
          "A equipe discute e realiza implementações visando acessibilidade da demanda que está desenvolvendo? (por exemplo: tab order, contraste, fontes, quantidade de ações para atingir o objetivo final, adição de hints explicativos, etc)",
          "A equipe se preocupa em como será a experiência do usuário com evoluções do produto e novas funcionalidades? (por exemplo: como será o primeiro acesso, se a interface é intuitiva)",
        ],
        nivelIntermediario: [
          "Todos da equipe discutem e se envolvem nas etapas de descoberta do produto?",
          "A equipe consegue argumentar com o cliente em relação às regras e restrições de negócio, para garantir uma melhor experiência para o usuário?",
          "A equipe tem clareza de quais são as principais etapas do processo do b_thinking e consegue adaptá-lo de acordo com a demanda?",
          "A equipe tem domínio dos métodos e ferramentas do b_thinking e sabe quais são mais indicados para cada necessidade?",
          "A equipe discute, realiza implementações e tem processos estruturados para garantir acessibilidade da demanda que está desenvolvendo? (por exemplo: tab order, contraste, fontes, quantidade de ações para atingir o objetivo final, adição de hints explicativos)",
          "A equipe se preocupa e valida em testes de usabilidade como será a experiência do usuário com evoluções do produto e novas funcionalidades? (por exemplo: como será o primeiro acesso, se a interface é intuitiva)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Entrega frequente",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe discute e define MVP das funcionalidades?",
          "A equipe inclui tarefas de melhoria no seu backlog e consegue desenvolver essas tarefas em um ritmo compatível com o esperado pelo projeto?",
          "A equipe se preocupa em concluir entregas funcionais antes de iniciar as próximas? (por exemplo: terminam front de uma funcionalidade antes de iniciar outras issues de back-end de outras funcionalidades, etc)",
        ],
        nivelIntermediario: [
          "A equipe consegue implementar MVPs de forma a fazer entregas funcionais menores e consequentemente mais frequentes?",
          "A equipe inclui tarefas de melhoria no seu backlog, tendo o indicador Tipo de esforço na categoria Evolução Bridge dentro do esperado pelo projeto?",
          "A equipe consegue realizar entregas incrementais para o cliente independente de restrições no lançamento dessas funcionalidades para produção?",
          "A equipe possui o indicador do Tipo de esforço na categoria Evolução (TED) dentro do esperado pelo projeto?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Valor de negócio",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Está claro para todos da equipe a lista de prioridades, incluindo issues relacionadas aos épicos, manutenções evolutivas e corretivas?",
          "As tarefas oriundas de monitoramentos e/ou corretivas são priorizadas de maneira eficaz?",
          "Há critérios de aceitação (requisitos) e estes são conferidos pela equipe após a conclusão de cada épico?",
          "A equipe busca compreender o valor que a demanda desenvolvida irá proporcionar?",
        ],
        nivelIntermediario: [
          "(B) As tarefas oriundas de monitoramentos e/ou corretivas são priorizadas de maneira eficaz?",

          "Está claro para todos da equipe a lista de prioridades e é construída baseada no impacto e no valor que estas prioridades geram, incluindo issues relacionadas aos épicos, manutenções evolutivas e corretivas?",
          "Há critérios de aceitação (requisitos) e estes são conferidos pela equipe após a conclusão de etapas do épico (por exemplo: história, demanda mãe, etc)?",
          "Todos da equipe consideram que os critérios de aceitação (requisitos) são úteis e suficientes/coerentes com as suas necessidades?",
          "A equipe busca compreender e consegue mensurar o valor que a demanda desenvolvida irá proporcionar?",
          "A equipe se preocupa em verificar se o valor esperado foi atingido? (por exemplo: perguntando para o cliente, consulta de uso, conversando com o usuário, etc)",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Satisfação do cliente",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "As entregas da equipe estão atendendo as expectativas do cliente?",
          "A equipe possui conhecimento de negócio suficiente para desenvolver soluções aderentes às necessidades dos clientes?",
          "O time de desenvolvimento compreende o cliente? (por exemplo: realização de sprint review com o cliente, apresentação do cliente pelo P.O, participação eventual em reuniões com o cliente, etc)",
          "A equipe se preocupa em manter as ferramentas de transparência claras e atualizadas?",
          "A equipe mostra as evoluções das funcionalidades para o cliente de acordo com a cadência estipulada pelo projeto?",
        ],
        nivelIntermediario: [
          "(B) A equipe se preocupa em manter as ferramentas de transparência claras e atualizadas?",
          "(B) A equipe mostra as evoluções das funcionalidades para o cliente de acordo com a cadência estipulada pelo projeto?",
          "A equipe avalia durante o processo se as expectativas do cliente estão sendo atendidas e toma ações para que elas sejam atendidas?",
          "A equipe possui conhecimento de negócio suficiente para propor soluções aderentes às necessidades dos clientes?",
          "A equipe entende os objetivos estratégicos do cliente? (por exemplo: ter uma reflexão na equipe se eles sabem o motivo de desenvolver aquela tarefa, alinhamento com a estratégia do cliente, etc)",
          "Todos da equipe acompanham os resultados da SPB e discutem como podem atuar para melhorar esses resultados?",
        ],
        nivelAvancado: [],
      },
      {
        nome: "Satisfação do usuário final",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe conhece e está trabalhando nas principais reclamações e problemas encontrados em produção? (por exemplo: tarefas vindas do suporte, pesquisa de satisfação, comentário das lojas, etc)",
          "A equipe conhece e acompanha feedbacks do usuário com a cadência estipulada pelo projeto? (por exemplo: comentários da planilha de satisfação ou das lojas dos aplicativos, etc)",
          "A equipe conhece as necessidades dos diferentes perfis de usuários (personas) que utilizam o sistema?",
          "O Product Owner e o Design se relacionam frequentemente com o usuário?",
          "A equipe valida as propostas de soluções com os usuários?",
        ],
        nivelIntermediario: [
          "(B) A equipe conhece e está trabalhando nas principais reclamações e problemas encontrados em produção? (por exemplo: tarefas vindas do suporte, pesquisa de satisfação, comentário das lojas, etc)",
          "A equipe propõe ações e gera resultados no tempo adequado a partir do que encontra nos feedbacks do usuário? (por exemplo: comentários da planilha de satisfação ou das lojas dos aplicativos)",
          "A equipe evita impactos negativos nos diferentes perfis de usuários que utilizam o sistema ao implementar uma nova solução?",
          "Todos da equipe compreendem o usuário? (por exemplo: participa de entrevistas mesmo que como ouvinte, acompanha visitas aos usuários, engajamento em cerimônias e dinâmicas de descobertas, lê os repasses sobre os dados coletados, etc)",
          "A equipe consegue priorizar as demandas e melhorias de acordo com os resultados da validação com usuários?",
          "A equipe gera métricas quantitativas baseadas no comportamento do usuário e consegue utilizá-las para propor melhorias? (por exemplo: geradas através do Grafana, Google Analytics, etc)",
          "A equipe valida as propostas de soluções com os usuários de modo a conseguir implementar melhorias antes da entrega da solução para produção?",
          "A equipe registra, compartilha e discute as descobertas obtidas pelos feedbacks do usuário quando afetam o produto como um todo?",
        ],
        nivelAvancado: [
          "(B) A equipe conhece e está trabalhando nas principais reclamações e problemas encontrados em produção? (por exemplo: tarefas vindas do suporte, pesquisa de satisfação, comentário das lojas, etc)",
          "A equipe propõe ações e acompanha os resultados no tempo adequado a partir de métricas encontradas nos feedbacks do usuário? (por exemplo: Atua em reclamações com maior porcentagem de comentários da planilha de satisfação ou das lojas dos aplicativos e verifica se as reclamações diminuem após as ações)",
          "A equipe evita impacto negativo nos diferentes perfis e documenta o conhecimento de modo a prevenir que o problema se repita em outras equipes? (por exemplo: criação de templates, revisão de matriz de módulos e impacto, etc)",
          "Todos da equipe se relacionam eventualmente com o usuário, realizando repasses sobre os dados coletados para os membros do projeto? (por exemplo: participa de entrevistas mesmo que como ouvinte, acompanha visitas aos usuários, engajamento em cerimônias e dinâmicas de descobertas, etc)",
          "A equipe valida as funcionalidades em ambientes de produção controlados antes que sejam disponibilizadas para todos os usuários e consegue implementar melhorias antes da entrega final quando identificadas? (por exemplo: teste beta, teste com app de produção, etc)",
          "A equipe consegue acompanhar, documentar e gerir métricas quantitativas baseadas no comportamento do usuário de maneira estruturada e consegue utilizá-las para propor melhorias? (por exemplo: O time possui ao menos um relatório do Google Analytics configurado e analisa esse relatório após o lançamento do módulo)",
          "A equipe valida as propostas de soluções com diversos perfis de usuários de modo a conseguir implementar melhorias antes da entrega da solução para produção? (por exemplo: perfis com diferentes necessidades de acessibilidade, diferentes faixas etárias, etc)",
          "A equipe registra, compartilha ou discute com outras equipes as descobertas obtidas pelos feedbacks do usuário quando afetam o produto como um todo?",
        ],
      },
    ],
  },
  {
    nome: "Pessoas sensacionais",
    categorias: [
      {
        nome: "Comunicação",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todos da equipe percebem que a comunicação interna é eficiente e transparente?",
          "Todos da equipe percebem que há interação e entrosamento entre todos?",
          "Todos da equipe conseguem dizer, de forma geral, o que todos estão fazendo?",
          "A maior parte da equipe realiza feedback? (Reuniões individuais, informais)",
          "Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, todo o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "A equipe é proativa ao comunicar mudanças que possam gerar impacto em outras equipes?",
          "Todos da equipe se preocupam em manter uma comunicação assíncrona e remote first?",
        ],
        nivelIntermediario: [
          "(B) Todos da equipe percebem que a comunicação interna é eficiente e transparente?",
          "(B) Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, toda o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "(B) A equipe é proativa ao comunicar mudanças que possam gerar impacto em outras equipes?",
          "Todos da equipe percebem que há interação e entrosamento entre todos os membros, proporcionando um ambiente confortável para sugerir melhorias mesmo que fora da sua área de atuação direta?",
          "Todos da equipe conseguem dizer, de forma geral, o que todos estão fazendo e se o andamento dessas tarefas está dentro do esperado?",
          "Todos da equipe consideram que o feedback realizado durante o ciclo é suficiente, contribuindo para o desenvolvimento pessoal e profissional de todos? (reuniões individuais, informais)",
          "Todos da equipe, independente da modalidade de trabalho, participam e se sentem incluídos em momentos de tomada de decisão?",
          "Os integrantes da equipe que não conseguirem participar de reuniões e decisões recebem todas as informações necessárias (sobre problemas ou demais assuntos da equipe) de forma assíncrona?",
          "Todos da equipe se sentem confortáveis e realizam troca de feedback dentro da equipe?",
        ],
        nivelAvancado: [
          "(B) Quando algum integrante da equipe realiza ou é informado de alguma mudança que impacta nas atividades, todo o restante da equipe também recebe essa informação de forma rápida e fácil?",
          "(I) Todos da equipe percebem que há interação e entrosamento entre todos os membros, proporcionando um ambiente confortável para sugerir melhorias mesmo que fora da sua área de atuação direta?",
          "(I) Todos da equipe conseguem dizer, de forma geral, o que todos estão fazendo e se o andamento dessas tarefas está dentro do esperado?",
          "(I) Todos da equipe, independente da modalidade de trabalho, participam e se sentem incluídos em momentos de tomada de decisão?",
          "A equipe possui políticas de comunicação internas bem definidas? (por exemplo: Slack ou Discord, canal X serve para tal coisa, mensagem de atualização do branch)",
          "Todos da equipe estão constantemente cientes das opiniões dos outros membros sobre o seu trabalho?",
          "A equipe possui políticas de comunicação definidas em relação às mudanças que possam gerar impacto em outras equipes? (por exemplo: tarefas que afetam o mobile, refatoração de módulo que afete outra equipe, modificação de regra de negócio, etc)",
          "A equipe registra as principais decisões garantindo a gestão do conhecimento da equipe e proporcionando insumos para quem não pôde estar presente contribuir?",
          "A equipe se sente confortável e realiza troca de feedback quando pertinente com qualquer bridger?",
          "Todos da equipe consideram que os feedbacks trocados internamente e externamente acontecem de maneira clara, são completos e acontecem na hora certa? (exemplo: acontecem logo após a ocorrência e/ou possuem exemplos)",
        ],
      },
      {
        nome: "Motivação",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe acompanha a motivação?",
          "A equipe toma ações quando a motivação dos membros está baixa?",
          "A equipe identifica quando mudanças na equipe ou nas demandas estão afetando a motivação dos membros?",
        ],
        nivelIntermediario: [
          "A equipe acompanha a motivação e consegue mensurá-la?",
          "A equipe encaminha ações buscando manter o time motivado?",
          "A equipe toma ações para construir um ambiente seguro para os membros compartilharem seus sentimentos?",
          "A equipe é capaz de adaptar suas práticas de modo a suavizar a desmotivação quando houver mudanças na equipe? (ex: mudança de demanda, rotatividade de membros, etc)",
        ],
        nivelAvancado: [
          "A equipe utiliza dados (feedback contínuo, métricas, observações) para prever e mitigar quedas de motivação antes que impactem o desempenho?",
          "A equipe consegue identificar e agir proativamente sobre fatores que afetam negativamente sua motivação?",
          "Os membros se sentem confortáveis e compartilham o seu estado atual e bem-estar pois sentem que o time é seguro?",
          "A equipe antecipa e ajusta suas práticas de forma proativa para evitar impactos negativos na motivação diante de mudanças na equipe ou nas demandas?",
        ],
      },
      {
        nome: "Autonomia e auto-organização",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "A equipe se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho?",
          "A equipe consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos?",
          "Há autonomia suficiente dentro da equipe para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "A equipe entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe?",
        ],
        nivelIntermediario: [
          "(B) Há autonomia suficiente dentro da equipe para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "A equipe é capaz de adaptar suas estratégias e metodologias de trabalho quando enfrenta mudanças significativas ou inesperadas no escopo ou nas prioridades do projeto?",
          "A equipe se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho mesmo quando desenvolvendo duas ou mais demandas em paralelo?",
          "A equipe consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos minimizando o impacto no planejamento?",
          "A equipe tem autonomia para propor evoluções técnicas? (por exemplo: bibliotecas diferentes, novas tecnologias, soluções criativas para problemas comuns, etc)",
          "A equipe entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe, envolvendo outras equipes e/ou o parceiro?",
          "A equipe tem autonomia para propor diferentes soluções para atingir os objetivos do produto priorizados em conjunto com o parceiro?",
          "A equipe tem autonomia para propor inovações nos processos de trabalho do Laboratório e dentro do projeto?",
        ],
        nivelAvancado: [
          "(B) Há autonomia suficiente dentro da equipe para utilizar ou propor diferentes metodologias para executar o seu trabalho da melhor forma?",
          "(I) A equipe é capaz de adaptar suas estratégias e metodologias de trabalho quando enfrenta mudanças significativas ou inesperadas no escopo ou nas prioridades do projeto?",
          "(I) A equipe se auto-organiza para determinar a melhor maneira de conseguir atingir o propósito do seu trabalho mesmo quando desenvolvendo duas ou mais demandas em paralelo?",
          "(I) A equipe consegue remover ou buscar soluções para impedimentos que estão atrapalhando o desenvolvimento da sua demanda com apoio de agentes externos minimizando o impacto no planejamento?",
          "(I) A equipe entende quando é necessário envolver a supervisão para resolver problemas dentro da equipe, envolvendo outras equipes e/ou o parceiro?",
          "A equipe tem autonomia e propõe evoluções técnicas? (por exemplo: bibliotecas diferentes, novas tecnologias, soluções criativas para problemas comuns, etc)",
          "A equipe possui autonomia e contribui para a visão de longo prazo do produto, propondo soluções inovadoras e alinhadas aos objetivos de negócio, mesmo que isso envolva mudanças significativas no escopo original?",
          "A equipe propõe inovações nos processos de trabalho do Laboratório e dentro do projeto?",
        ],
      },
      {
        nome: "Kaizen - Melhoria contínua",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "As reuniões de retrospectiva estão inseridas na cultura da equipe?",
          "A equipe identifica e trabalha oportunidades de melhoria nos seus processos?",
          "A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "Os planos de ação da b_agile e das retrospectivas são claros, possuem responsável definido e são acompanhados?",
        ],
        nivelIntermediario: [
          "(B) A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "Todos da equipe consideram que a reunião de retrospectiva é útil e eficiente, gerando ações de melhoria contínua na equipe?",
          "A equipe é totalmente autônoma na identificação e proativa ao trabalhar oportunidades de melhorias em seus processos?",
          "Os planos de ação da b_agile e das retrospectivas são claros, possuem responsável definido, possuem prazo e são acompanhados em toda sprint?",
          "Todos da equipe possuem mentalidade de melhoria contínua em relação às próprias atividades?",
          "A equipe realiza as atividades de melhoria contínua da equipe independente de intervenção do SM? (por exemplo: retrospectiva e ações derivadas, planos de ação da b_agile, etc)",
          "A equipe consegue identificar quais melhorias são as mais valiosas? E faz isso de forma consistente, não apenas com base em achismos?",
          "A equipe executa os planos de ação ao longo do tempo, sem acúmulo de tarefas ao final dos prazos?",
        ],
        nivelAvancado: [
          "(B) A equipe reserva tempo para executar atividades de melhoria contínua nos processos?",
          "(I) A equipe considera que a reunião de retrospectiva é útil e eficiente, gerando ações de melhoria contínua na equipe?",
          "(I) A equipe é totalmente autônoma na identificação e proativa ao trabalhar oportunidades de melhorias em seus processos?",
          "(I) Os planos de ação da b_agile e das retrospectivas são claros, possuem responsável definido, possuem prazo e são acompanhados em toda sprint?",
          "(I) A equipe executa os planos de ação ao longo do tempo, sem acúmulo de tarefas ao final dos prazos?",
          "Todos da equipe possuem mentalidade de melhoria contínua em relação aos processos da equipe, mesmo que não seja da sua responsabilidade direta?",
          "A equipe faz o planejamento e realiza as atividades de melhoria contínua da equipe independente de intervenção do SM? (por exemplo: retrospectiva e ações derivadas, planos de ação da b_agile, OKRs, etc)",
          "A equipe aborda a raiz dos problemas no planejamento das melhorias, solucionando os problemas de maneira eficaz ao invés de soluções paliativas?",
          "A equipe consegue identificar quais melhorias são as mais valiosas utilizando métodos de priorização? (Exemplos: GUT, pareto, estratificação, histograma...)",
          "A equipe não considera como critério de sucesso a conclusão do plano de ação, mas sim, se as ações realizadas foram capazes de gerar o benefício desejado durante a criação do plano de ação?",
          "Após a conclusão do plano de ação, caso o problema inicial não esteja resolvido, a equipe traça um novo plano de ação, levando em conta os aprendizados da execução anterior?",
        ],
      },
      {
        nome: "Colaboração e Interdisciplinaridade",
        nota: null,
        comentario: null,
        nivelSelecionado: Nivel.BASICO,
        nivelBasico: [
          "Todo o time de desenvolvimento auxilia as demais quando são identificados gargalos/impedimentos?",
          "Todos da equipe estão dispostos a realizar atividades simples de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "A equipe valida prazos e prioridades de desenvolvimento com equipes que podem ser impactadas por suas demandas, garantindo que ambos estejam alinhados antes de seguir com as entregas?",
        ],
        nivelIntermediario: [
          "(B) A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "Todo o time de desenvolvimento auxilia as demais para evitar o surgimento de gargalos/impedimentos? (por exemplo: auxilia em testes exploratórios, auxilia em criação de ambientes, escrita de testes automatizados que reduzam a carga de trabalho dos testers, etc)",
          "Todos da equipe estão dispostos a realizar atividades de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "A equipe mantém um processo de colaboração com demais equipes? (Ex: Membros da equipe oferecem apoio em dúvidas de testes, negócio, desenvolvimento, revisão de código, etc)",
          "Todos da equipe colaboram para alcançar os resultados chave do time e projeto? (por exemplo: KRs do time, objetivos da equipe, KRs do projeto, etc)",
          "A equipe possui processos mapeados que definem como ocorre a troca de responsabilidades entre diferentes papéis, promovendo e facilitando a colaboração? (por exemplo: processo para seguir quando um desenvolvedor realizar testes na etapa de QA)",
        ],
        nivelAvancado: [
          "(B) A equipe consegue encaminhar atividades e resolver impedimentos mesmo na ausência do colaborador responsável?",
          "(I) Todos da equipe estão dispostos a realizar atividades de outras áreas durante o desenvolvimento da tarefa para facilitar o dia-a-dia? (por exemplo: pequenos ajustes no código ou na documentação, pequenos testes na etapa de desenvolvimento, retestar alterações no código feitas na etapa de code review, etc)",
          "(I) A equipe mantém um processo de colaboração com demais equipes? (Ex: Membros da equipe oferecem apoio em dúvidas de testes, negócio, desenvolvimento, revisão de código, etc)",
          "(I) A equipe possui processos mapeados que definem como ocorre a troca de responsabilidades entre diferentes papéis, promovendo e facilitando a colaboração? (por exemplo: processo para seguir quando um desenvolvedor realizar testes na etapa de QA)",
          "Todo o time de desenvolvimento está preparado para atuar de forma fluida em áreas que não são de sua especialidade, contribuindo diretamente em atividades de outros papéis sem que seja necessário direcionamento? (por exemplo: configuração de pipelines de CI/CD, testers realizando a otimização/revisão de código, criação/execução de cenários de testes, monitoramento de ambientes, decisões técnicas sobre as entregas, etc.)",
          "A equipe toma iniciativa para colaborar e sugerir inovações, contribuindo de forma ativa para a evolução do projeto e da organização?",
          "A equipe constrói soluções de forma colaborativa com outras equipes, compartilhando responsabilidades e garantindo a integração contínua? (Ex: A equipe se engaja em sprints conjuntas, integra tecnologias de diferentes times e ajusta processos colaborativos.)",
          "A equipe utiliza uma abordagem colaborativa para discutir o progresso, desafios e soluções, envolvendo todos os membros nas decisões? (Planejamento de prazos, gestão de riscos, discovery, etc)",
        ],
      },
    ],
  },
];
